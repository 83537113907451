const dev = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-api-dev-attachmentsbucket-tt66n6yxyxir"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://5iygrk83kd.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_AJoyPByzT",
      APP_CLIENT_ID: "76qjf9sua4a3ipsfirgmujb6h",
      IDENTITY_POOL_ID: "us-east-1:99d5a08b-b2be-45ce-8cc5-d2a276dbfe09"
    }
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "notes-app-xjjon"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://jke8p8wqyl.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_AJoyPByzT",
    APP_CLIENT_ID: "76qjf9sua4a3ipsfirgmujb6h",
    IDENTITY_POOL_ID: "us-east-1:99d5a08b-b2be-45ce-8cc5-d2a276dbfe09"
  }
};
  
const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}