import React from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Loadable from "react-loadable";
import LoadingComponent from "./components/LoadingComponent";
 
const AsyncHome = Loadable({
    loader: () => import("./containers/Home"),
    loading: LoadingComponent
});
const AsyncLogin = Loadable({
    loader: () => import("./containers/Login"),
    loading: LoadingComponent
});
const AsyncSignup = Loadable({
    loader: () => import("./containers/Signup"),
    loading: LoadingComponent
});
const AsyncSettings = Loadable({
    loader: () => import("./containers/Settings/Settings"),
    loading: LoadingComponent
});
const AsyncResetPassword = Loadable({
    loader: () => import("./containers/ResetPassword"),
    loading: LoadingComponent
});
const AsyncChangePassword = Loadable({
    loader: () => import("./containers/Settings/ChangePassword"),
    loading: LoadingComponent
});
const AsyncNotes = Loadable({
    loader: () => import("./containers/Notes"),
    loading: LoadingComponent
});
const AsyncNewNote = Loadable({
    loader: () => import("./containers/NewNote"),
    loading: LoadingComponent
});

export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/" exact component={AsyncHome} props={childProps} />
        <UnauthenticatedRoute path="/login" exact component={AsyncLogin} props={childProps} />
        <UnauthenticatedRoute path="/signup" exact component={AsyncSignup} props={childProps} />
        <UnauthenticatedRoute path="/login/reset" exact component={AsyncResetPassword} props={childProps}/>
        <AuthenticatedRoute path="/settings" exact component={AsyncSettings} props={childProps} />
        <AuthenticatedRoute path="/settings/password" exact component={AsyncChangePassword} props={childProps} />
        <AuthenticatedRoute path="/notes/new" exact component={AsyncNewNote} props={childProps} />
        <AuthenticatedRoute path="/notes/:id" exact component={AsyncNotes} props={childProps} />
        <Route component={NotFound} />
    </Switch>
